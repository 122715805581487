
import { mixin as clickaway } from 'vue-clickaway'
import { mapActions } from 'vuex'

export default {
  mixins: [clickaway],
  props: {
    name: {
      type: String,
      default: 'header'
    },
    text: {
      default: 'Dropdown',
      type: String
    },
    position: {
      default: 'top',
      type: String
    },
    align: {
      default: 'right',
      type: String
    },
    width: {
      default: 'default',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    dropdownMargin: {
      default: 'mt-2',
      type: String
    },
    dropdownType: {
      default: '',
      type: String
    },
    hasOverflowHidden: {
      default: true,
      type: Boolean
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    overflowClass() {
      if(this.hasOverflowHidden) {
        return 'overflow-hidden overflow-y-auto'
      }
      return ''
    },
    overflowParentClass() {
      if(this.hasOverflowHidden) {
        return 'overflow-hidden'
      }
      return ''
    },
    widthClass () {
      const classesMap = {
        default: 'w-56',
        lg: 'w-[92vw] md:w-72',
        xl: this.name === 'headermobile' ? 'w-[17rem] sm:w-[22.5rem]' :  this.name !== 'footer' ? 'w-[92vw] md:w-[22.5rem]' : 'w-[88vw] sm:w-[92vw] md:w-[22.5rem]',
        auto: 'w-auto'
      }

      return classesMap[this.width]
    },
    alignPositionClass () {
      const classesMap = {
        top: 'top-8',
        bottom: 'bottom-14'
      }

      return classesMap[this.position]
    },
    alignClass () {
      const classesMap = {
        right: this.dropdownType === 'locales' && this.name === 'headermobile' ? 'origin-top-left ltr:left-0 rtl:left-auto md:right-0' : 'origin-top-right rtl:left-0 ltr:right-0',
        left: 'origin-top-left start-0'
      }

      return classesMap[this.align]
    }
  },
  watch: {
    '$route' (to, from) {
      this.away()
    },
    isOpen (value) {
      this.handleClickOutside(value)
    }
  },
  mounted () {
    // this.$bus.$on('dropdown:close', () => (this.isOpen = false))
  },
  methods: {
    ...mapActions('locales', ['handleClickOutside']),

    hasSlot (name = 'default') {
      return !!this.$slots[name] || !!this.$scopedSlots[name]
    },
    away () {
      this.isOpen = false
      this.$emit('click-outside', false)
    },
    onClickOpen () {
      if (!this.disabled) {
        this.isOpen = !this.isOpen
      }
    }
  }
}
