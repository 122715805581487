
import { mapGetters, mapActions } from 'vuex'
import { sortBy } from 'lodash'
import CountryFlag from 'vue-country-flag'
import GlobeIcon from '@/components/GlobeIcon'
import AngleDown from '@/assets/svg/heroicons/arrow-down.svg?inline'

export default {
  components: {
    CountryFlag,
    GlobeIcon,
    AngleDown
  },
  props: {
    name: {
      type: String,
      default: 'header'
    },
    color: {
      type: String,
      required: false,
      default: 'white'
    },
    position: {
      type: String,
      default: 'top'
    }
  },
  data () {
    return {
      isProductPage: false,
      urlPath: '',
      continentsKey: 0,
      countriesKey: 0,
      localesKey: 0,
      scrollPosition: 0,
      defaultLoad: true,
      localeLoading: true,
      isStorePage: false
    }
  },
  watch: {
    $route () {
      if (window.location.href.includes('/products/')) {
        this.isProductPage = true
      } else {
        this.isProductPage = false
      }
      if (window.location.href.includes('/stores')) {
        this.isStorePage = true
      } else {
        this.isStorePage = false
      }
    },
    hasClickoutSide (value) {
      if (!value) {
        setTimeout(() => {
          this.resetValues()
          this.defaultLoad = true
          this.handleLocaleUpdating()
          this.$forceUpdate()
        }, 100)
      }
    }
  },
  computed: {
    ...mapGetters({
      locales: 'locales/getLocales',
      country: 'getCountry',
      locale: 'getLocale',
      hasClickoutSide: 'locales/getHasClickoutSide'
    }),
    getContinents () {
      const continents = sortBy(this.locales, 'id')
      for (let i = 0; i < continents.length; i++) {
        // set continent
        continents[i].findIndex((x) => {
          if (x.slug === this.country) {
            this.continentsKey = i
          }
        })
      }

      return this.locales
    },
    getCountries () {
      let countries = this.locales
      countries = sortBy(countries, 'id')

      if (this.defaultLoad) {
        for (let i = 0; i < countries.length; i++) {
          // set country
          let j = 0
          countries[i].findIndex((x) => {
            if (x.slug === this.country) {
              this.countriesKey = j
            }
            j++
          })
        }
      }

      return countries[this.continentsKey]
    },
    getLocales () {
      let locales = this.locales

      locales = sortBy(locales, 'id')
      if (this.defaultLoad) {
        // set locale
        for (let i = 0; i < locales.length; i++) {
          locales[i].findIndex((x) => {
            if (x.slug === this.country) {
              this.localesKey = x.urls.findIndex(
                y => y.locale_code === this.locale
              )
            }
          })
        }
      }

      return locales[this.continentsKey]
    },
    isCountryDisable () {
      return !!this.getCountries.find(
        _country =>
          _country.name.includes('Global') ||
          _country.name.includes('International')
      )
    }
  },
  mounted () {
    this.isProductPage = window.location.href.includes('/products/')
    this.isStorePage = window.location.href.includes('/stores')
    // this.$store.dispatch('locales/fetchLocales')
    window.addEventListener('scroll', this.updateScroll)
    this.localeLoading = false
  },
  methods: {
    ...mapActions('locales', ['handleLocaleUpdating']),
    collapseHeight (event) {
      const tabContent = document.querySelector(
        `#${event.target.id}-collapse.tab-content`
      )
      const localeSelect = document.querySelector(`#${event.target.id}-select`)

      if (tabContent.classList.contains('max-h-0')) {
        tabContent.classList.remove('max-h-0', 'mb-12')
        localeSelect.classList.add('hidden')
      } else {
        tabContent.classList.add('max-h-0', 'mb-12')
        localeSelect.classList.add('block')
        localeSelect.classList.remove('hidden')
      }
    },
    resetValues () {
      this.defaultLoad = false
      this.countriesKey = 0
      this.localesKey = 0
    },
    selectLocale (event) {
      this.localesKey = this.getLocales[event.target.value].urls.findIndex(
        _url => _url.default
      )
      // this.localesKey = 0
    },
    changeLocale (name) {
      const selectElement = document.querySelector(
        `#${name}.locale-selected.selected`
      )
      const selectedOptionElement = selectElement
        .querySelector(`option[value="${selectElement.value}"]`)
        .getAttribute('data-url')
      this.$cookies.set('CountryLocale', `${selectedOptionElement.split('/').pop().split('-')[0]}/${selectedOptionElement.split('/').pop().split('-')[1]}`)
      window.location.href = selectedOptionElement
    },
    updateScroll () {
      this.scrollPosition = window.scrollY
    }
  },
  filters: {
    capitalize (data) {
      const capitalized = []

      data.split(' ').forEach((word) => {
        capitalized.push(
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
      })

      return capitalized.join(' ')
    }
  }
}
